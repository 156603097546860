import { lazy, Suspense, useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

import Spinner from './components/Spinner/Spinner';
import Community from './pages/Community';
import ProtectedAdmin from './protected/ProtectedAdmin';
import ProtectedEvaluator from './protected/ProtectedEvaluator';
import ProtectedTeam from './protected/ProtectedTeam';
import { AppContext, AppContextValueType } from './store/app-context';
import useWCIWrapper from './hooks/useWCIWrapper';

const queryClient = new QueryClient();

// lazy some components
const Tasks = lazy(() => import('./pages/Tasks'));
const Task = lazy(() => import('./pages/Task'));
const FollowUp = lazy(() => import('./pages/FollowUp'));
const Profile = lazy(() => import('./pages/Profile'));
const Rankings = lazy(() => import('./pages/Rankings'));
const Team = lazy(() => import('./pages/Team'));
const EditTeam = lazy(() => import('./pages/EditTeam'));
const CreateTeam = lazy(() => import('./pages/CreateTeam'));
const JoinTeam = lazy(() => import('./pages/JoinTeam'));
const JoinTeams = lazy(() => import('./pages/JoinTeams'));
const Login = lazy(() => import('./pages/Login'));
const Terms = lazy(() => import('./pages/Terms'));
const Admin = lazy(() => import('./pages/admin/Admin'));
const ManageTeams = lazy(() => import('./pages/admin/ManageTeams'));
const ManageTeam = lazy(() => import('./pages/admin/ManageTeam'));
const ManageProfiles = lazy(() => import('./pages/admin/ManageProfiles'));
const ManageProfile = lazy(() => import('./pages/admin/ManageProfile'));
const ManageContents = lazy(() => import('./pages/admin/ManageContents'));
const ManageContent = lazy(() => import('./pages/admin/ManageContent'));
const ManageThemes = lazy(() => import('./pages/admin/ManageThemes'));
const ManageTheme = lazy(() => import('./pages/admin/ManageTheme'));
const ManageRegions = lazy(() => import('./pages/admin/ManageRegions'));
const ManageRegion = lazy(() => import('./pages/admin/ManageRegion'));
const ManageSettings = lazy(() => import('./pages/admin/ManageSettings'));
const ManageSetting = lazy(() => import('./pages/admin/ManageSetting'));
const Syncing = lazy(() => import('./pages/admin/Syncing'));
const Evaluate = lazy(() => import('./pages/evaluator/Evaluate'));
const NotFound = lazy(() => import('./pages/404'));
const MarkTaskAsCompleted = lazy(() => import('./pages/MarkTaskAsCompleted'));
const WrapperWithParamIdAsKey = lazy(
  () => import('./components/WrapperWithParamIdAsKey/WrapperWithParamIdAsKey')
);
const Autocomplete = lazy(() => import('./pages/Autocomplete'));
const NavigateWithParams = lazy(
  () => import('./components/NavigateWithParams/NavigateWithParams')
);

function App(): JSX.Element {
  const { appData, okToLoadApp } = useContext<AppContextValueType>(AppContext);
  const { withWrapper } = useWCIWrapper();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // if project label as end path, ensure trailing "/" in order to have the active menu item highlighted
    const paths = location.pathname.split('/');
    if (
      paths.length === 2 &&
      paths[0] === '' &&
      appData.projects.find((project) => project.prj_label === paths[1])
    ) {
      navigate(`${location.pathname}/`, { replace: true });
    }
  }, [appData]);

  let DefaultComponent = <Community />;

  if (appData.mainMenuItems?.[0]?.name === 'Tasks') {
    DefaultComponent = <Tasks />;
  }

  const routes = useRoutes([
    { path: '/', element: withWrapper(DefaultComponent) },
    {
      path: '/:prj_label',
      children: [
        { index: true, element: withWrapper(DefaultComponent) },
        { path: 'community', element: withWrapper(<Community />) },
        { path: 'tasks', element: withWrapper(<Tasks />) },
        { path: 'tasks/view/:tabPath', element: withWrapper(<Tasks />) },
        { path: 'tasks/:id/:teamId', element: withWrapper(<Task />) },
        {
          path: 'tasks/:id',
          element: withWrapper(<WrapperWithParamIdAsKey Component={Task} />),
        },
        {
          path: 'mark-task-as-completed/:id',
          element: withWrapper(
            <WrapperWithParamIdAsKey Component={MarkTaskAsCompleted} />
          ),
        },
        {
          path: 'follow-up/:tabPath?/:subTabPath?',
          element: withWrapper(<FollowUp />),
        },
        {
          path: 'profile',
          element: withWrapper(<Profile key="profile-mine" />),
        },
        {
          path: 'profile/:id',
          element: withWrapper(<WrapperWithParamIdAsKey Component={Profile} />),
        },
        { path: 'rankings', element: withWrapper(<Rankings />) },
        { path: 'terms', element: withWrapper(<Terms />) },
        {
          // protected admin routes
          path: 'admin',
          element: <ProtectedAdmin isAdmin={appData.isAdmin} />,
          children: [
            { path: 'dashboard', element: withWrapper(<Admin />) },
            { path: 'manage-teams/:id', element: withWrapper(<ManageTeam />) },
            { path: 'manage-teams', element: withWrapper(<ManageTeams />) },
            {
              path: 'manage-profiles/:id',
              element: withWrapper(
                <WrapperWithParamIdAsKey Component={ManageProfile} />
              ),
            },
            {
              path: 'manage-profiles',
              element: withWrapper(<ManageProfiles />),
            },
            {
              path: 'manage-contents/:id',
              element: withWrapper(
                <WrapperWithParamIdAsKey Component={ManageContent} />
              ),
            },
            {
              path: 'manage-contents',
              element: withWrapper(<ManageContents />),
            },
            {
              path: 'manage-themes/:id',
              element: withWrapper(
                <WrapperWithParamIdAsKey Component={ManageTheme} />
              ),
            },
            { path: 'manage-themes', element: withWrapper(<ManageThemes />) },
            {
              path: 'manage-regions/:id',
              element: withWrapper(
                <WrapperWithParamIdAsKey Component={ManageRegion} />
              ),
            },
            {
              path: 'manage-regions',
              element: withWrapper(<ManageRegions />),
            },
            {
              path: 'manage-settings/:id',
              element: withWrapper(
                <WrapperWithParamIdAsKey Component={ManageSetting} />
              ),
            },
            {
              path: 'manage-settings',
              element: withWrapper(<ManageSettings />),
            },
            {
              path: 'syncing',
              element: withWrapper(<Syncing />),
            },
          ],
        },
        {
          // protected evaluator routes
          path: 'evaluator',
          element: <ProtectedEvaluator isEvaluator={appData.isEvaluator} />,
          children: [{ path: 'tasks', element: withWrapper(<Evaluate />) }],
        },
        {
          path: 'team/:id',
          element: withWrapper(<WrapperWithParamIdAsKey Component={Team} />),
        },
        {
          // protected team routes - my team
          element: <ProtectedTeam target="my-team" />,
          children: [
            { path: 'team', element: withWrapper(<Team />) },
            { path: 'team/edit', element: withWrapper(<EditTeam />) },
          ],
        },
        {
          // protected team routes - join team
          element: <ProtectedTeam target="join-team" />,
          children: [
            { path: 'create-team', element: withWrapper(<CreateTeam />) },
            { path: 'join-team', element: withWrapper(<JoinTeam />) },
            { path: 'join-teams', element: withWrapper(<JoinTeams />) },
          ],
        },
        { path: 'auto-complete/:uuid', element: withWrapper(<Autocomplete />) },
        // Login
        { path: 'login', element: <Login /> },
      ],
    },
    // make sure (old) team invite link without project label gets redirected correctly
    {
      path: 'team/:id',
      element: (
        <NavigateWithParams
          to={(params: any) => `/we-know/team/${params.id}`}
          replace
        />
      ),
    },
    // Login
    { path: 'login', element: <Login /> },
  ]);

  if (!okToLoadApp) {
    return <Spinner />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div />}>{routes ?? <NotFound />}</Suspense>
    </QueryClientProvider>
  );
}

export default App;
